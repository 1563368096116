import { Component, Injector, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { CapDoDonViHanhChinh, ComboBoxEnumCode, DonViHanhChinhDto, DonViHanhChinhServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalComponentBase } from '@shared/common/modal-component-base';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import * as $ from 'jquery';

@Component({
    templateUrl: './don-vi-hanh-chinh-edit-modal.component.html',
})
export class DonViHanhChinhEditModalComponent extends ModalComponentBase implements OnInit {
    formId = 'formValidateDonViHanhChinh';

    @Input() isShowModal: boolean = false;
    @Input() dataItem: DonViHanhChinhDto;
    ComboBoxEnumCode = ComboBoxEnumCode;
    CapDoDonViHanhChinh = CapDoDonViHanhChinh;

    isShowTinh: boolean = false;
    isShowHuyen: boolean = false;
    isShowXa: boolean = false;

    constructor(injector: Injector, private donViHanhChinhService: DonViHanhChinhServiceProxy) {
        super(injector);
    }

    ngOnInit(): void {
        if (!AppUtilityService.isNullOrWhiteSpace(this.dataItem.code)) {
            this.getByCode(this.dataItem.code);
        } else {
            this.clearForm();
        }
    }

    getByCode(code: string): void {
        abp.ui.setBusy();
        this.donViHanhChinhService
            .getByCode(code)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((response) => {
                let data = response;
                console.log('getByCode,',response);

                this.dataItem = data;
            });
    }

    save(): void {
        if (AppUtilityService.IsNullValidateForm(this.formId)) {
            abp.notify.error(this.l('_BanCanNhapDuDuLieuCacTruongCoDauSaoDo'));
            return;
        }
        abp.ui.setBusy();
        this.donViHanhChinhService
            .createOrUpdate(this.dataItem)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((result) => {
                if (result.isSuccessful) {
                    this.notify.success(this.l('SavedSuccessfully'));
                    if (this.isContinue) {
                        this.clearForm();
                    } else {
                        this.success(result.dataResult);
                    }
                } else {
                    this.notify.error(this.l('HasError'));
                }
            });
    }

    clearForm() {
        this.dataItem = Object.assign(new DonViHanhChinhDto(), {
            isActive: true,
            capDo: CapDoDonViHanhChinh.Thon,
        });
        this.checkViewByCapDo();
    }

    checkViewByCapDo() {
        if (this.dataItem.capDo) {
            this.isShowTinh = this.dataItem.capDo > CapDoDonViHanhChinh.Tinh;
            this.isShowHuyen = this.dataItem.capDo > CapDoDonViHanhChinh.Huyen;
            this.isShowXa = this.dataItem.capDo > CapDoDonViHanhChinh.Xa;
        }
    }
    //#region UX_JQuery

    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {
            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    dropDrapModal() {
        let modalContent: any = $('.ant-modal-content');
        modalContent.draggable({
            handle: '.ant-modal-header',
        });
    }

    ngAfterViewInit(): void {
        this.dropDrapModal();
        this.fullScreenClick();
    }
    //#endregion
}
