import {
    Component,
    Input,
    OnInit,
    OnChanges,
    AfterViewInit,
    Output,
    EventEmitter,
    SimpleChanges,
    Injector,
    forwardRef
} from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ComboItemDto } from '@shared/service-proxies/service-proxies';
import { LocalizationService } from 'abp-ng2-module';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DestroyRxjsService } from '@shared/destroy-rxjs.service';
import { BaseComboComponent } from '../base-combo-abstract';
import { NzSelectModeType } from 'ng-zorro-antd/select';
import { AppUtilityService } from '../../../common/custom/utility.service';
import _ from 'lodash';

@Component({
    selector: 'combo-select',
    templateUrl: './combo-select.component.html',
    styleUrls: ['./combo-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ComboSelectComponent),
            multi: true
        },
        DestroyRxjsService]
})
export class ComboSelectComponent implements OnInit, ControlValueAccessor {
    @Output() eventChange = new EventEmitter();
    control = new FormControl();
    optionListSource: ComboItemDto[] = [];
    optionList: ComboItemDto[] = [];

    @Input() set source(v: ComboItemDto[]) {
        this.optionListSource = v;
        this.optionList = v;
    }
    @Input() placeHolder = '-Chon-';
    @Input() AllowClear: boolean = true;

    @Input() isGroup: boolean = false;

    _value: any;

    _isDisabled = false;
    @Input()
    get disabled() {
        return this._isDisabled;
    }

    set disabled(v: boolean) {
        this._isDisabled = v;
    }

    localization: LocalizationService;
    constructor(
        injector: Injector
    ) {
        this.localization = injector.get(LocalizationService);
    }

    ngOnInit() {
    }

    onChange: any = () => {
    };
    onTouched: any = () => {
    };

    onFocus(event: any): void {
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(value: any): void {
        this.control.patchValue(value);
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.control.disable();
        } else {
            this.control.enable();
        }
    }

    onChangeValue(event) {
        this.onChange(event);

        let obj = this.optionList.find((m) => m.id == event);
        this.eventChange.emit(obj);
    }

    search(value: string): void {
        if (AppUtilityService.isNotAnyItem(this.optionListSource) === true) {
            this.optionList = [];
            return;
        }
        const searchTxt = AppUtilityService.getFullTextSearch(value);
        let fOption = this.optionListSource;
        if (AppUtilityService.isNotNull(searchTxt)) {
            fOption = _.filter(fOption, (s) => {
                //Ten viet tat
                const ftsVietTat = AppUtilityService.searchVietTat(s.name) || "";
                const checkVietTat = ftsVietTat.indexOf(searchTxt) > -1;
                //Ten khong dau
                const ftsName = AppUtilityService.getFullTextSearch(s.name) || "";
                const checkName = ftsName.indexOf(searchTxt) > -1;
                return checkName || checkVietTat;
            });
        }
        this.optionList = fOption;
    }
}
