import { Component, Injector, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ComboBoxEnumCode, KhoaPhongDto, KhoaPhongServiceProxy,  } from '@shared/service-proxies/service-proxies';
import { ModalComponentBase } from '@shared/common/modal-component-base';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import * as $ from 'jquery';
import { Hotkey } from 'angular2-hotkeys';

@Component({
    templateUrl: './khoa-phong-create-edit-modal.component.html',
})
export class KhoaPhongCreateEditModalComponent extends ModalComponentBase implements OnInit {
    formId = 'formValidateKhoaPhong';
    @Input() dataItem: KhoaPhongDto;
    ComboBoxEnumCode = ComboBoxEnumCode
    coSoId = this.appSession.user.coSoId > 0 ? this.appSession.user.coSoId : 0;

    saving = false;

    constructor(injector: Injector,
        private _KhoaPhongService: KhoaPhongServiceProxy,
    ) {
        super(injector);
    }

    initHotkeys() {
        this.hotkeys = [];
        this.hotkeys.push(new Hotkey('f10', (): boolean => {
            this.save();
            return false;// prevent bubbling
        },['INPUT', 'TEXTAREA', 'SELECT']));
    }



    ngOnInit(): void {
        //F5 createHotkeys
        this.createHotkeys();
        if (this.dataItem.id > 0) {
            this.getInfoById(this.dataItem.id);
        } else {
            this.clearForm();
        }
    }

    getInfoById(id: number): void {
        abp.ui.setBusy();
        this._KhoaPhongService
            .getById(id)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((response) => {
                let data = response;
                this.dataItem = data;
            });
    }

    save(): void {
        if (AppUtilityService.IsNullValidateForm(this.formId)) {
            abp.notify.error(this.l('_BanCanNhapDuDuLieuCacTruongCoDauSaoDo'));
            return;
        }
        abp.ui.setBusy();
        this._KhoaPhongService
            .createOrUpdate(this.dataItem)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((result) => {
                if (result.isSuccessful) {
                    let dataResult = result.dataResult;
                    this.notify.success(this.l('SavedSuccessfully'));
                    if (this.isContinue) {
                        this.clearForm();
                    } else {
                        this.success({...dataResult, value: dataResult.id, name: dataResult.tenKhoaPhong, data: dataResult});
                    }
                } else {
                    this.notify.error(this.l('HasError'));
                }
            });
    }

    send() {

    }

    clearForm() {
        
        this.dataItem = new KhoaPhongDto();
        this.dataItem.coSoId = this.appSession.user?.coSoId;
    }
}
