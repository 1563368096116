<ng-select #vcSelect
           [items]="options"
           [multiple]="true"
           [closeOnSelect]="false"
           [searchable]="true"
           [groupBy]="isGroup?'groupName':''"
           [selectableGroup]="isGroup"
           [selectableGroupAsModel]="false"
           bindLabel="name"
           bindValue="id"
           placeholder="{{placeHolder}}"
           [formControl]="control"
           (change)="onChangeSelect()"
           [notFoundText]="notFoundText"
           [searchFn]="testSearch" (search)="onSearch($event)">

    <!--<ng-template ng-header-tmp let-search="searchTerm">

    </ng-template>-->
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value" *ngFor="let item of items | slice:0:multiMaxShowItem">
            {{item.name}}
            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
        </div>
        <div class="ng-value" *ngIf="items.length > multiMaxShowItem">
            <span class="ng-value-label">+{{items.length - multiMaxShowItem}}...</span>
        </div>
    </ng-template>
    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

        <input id="item-{{index}}" type="checkbox"
               [ngModel]="control.value|checkComboGroup:options:item.groupName" />
        {{item.groupName | uppercase}}
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.name}}
    </ng-template>
    <ng-template ng-footer-tmp let-search="searchTerm">
        <div>
            <button class="btn btn-outline-success btn-sm" (click)="selectAll()" style="margin-right:5px;">
                {{'_ChonToanBo' | localize}}
            </button>
            <button class="btn btn-outline-secondary btn-sm" (click)="vcSelect.clearModel();clearAll();">
                {{'_BoChonToanBo' | localize}}
            </button>
        </div>
    </ng-template>
</ng-select>
