import { Component, Injector, Input, OnInit } from "@angular/core";
import { AppUtilityService } from "@app/shared/common/custom/utility.service";
import { ModalComponentBase } from "@shared/common/modal-component-base";
import { BoNganhQuanLyDto, DotTapHuanDto, ComboBoxEnumCode, DMBoNganhQuanLyServiceProxy, DotTapHuanServiceProxy } from "@shared/service-proxies/service-proxies";
import * as $ from 'jquery';
import { finalize } from "rxjs/operators";

@Component({
    templateUrl: './dot-tap-huan-edit-modal.component.html'
})
export class DotTapHuanEditModalComponent extends ModalComponentBase implements OnInit {
    formId = 'formBoNganhQuanLy';
    @Input() dataItem: DotTapHuanDto;
    @Input() isView = false;

    ComboBoxEnumCode = ComboBoxEnumCode;

    constructor(
        injector: Injector,
        private _dotTapHuanService: DotTapHuanServiceProxy,
        ) {
        super(injector);
    }

    ngOnInit(): void {
        if (this.dataItem.id > 0) {
            this.getInfoById(this.dataItem.id);
        } else {
            this.clearForm();
        }
    }

    getInfoById(id): void {
        abp.ui.setBusy();
        this._dotTapHuanService
            .getById(id)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((response) => {
                this.dataItem = response;
            });
    }

    save(){
        if (AppUtilityService.IsNullValidateForm(this.formId)) {
            abp.notify.error(this.l('_BanCanNhapDuDuLieuCacTruongCoDauSaoDo'));
            return;
        }

        console.log(this.dataItem, 'this.dataItem');
        abp.ui.setBusy();
        this._dotTapHuanService
            .createOrUpdate(this.dataItem)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((result) => {
                if (result.isSuccessful) {
                    this.notify.success(this.l('SavedSuccessfully'));
                    if (this.isContinue) {
                        this.clearForm();
                    } else {
                        this.success(result.dataResult);
                    }
                } else {
                    this.notify.error(result.errorMessage);
                }
            });
    }


    clearForm() {
        this.dataItem = Object.assign(new DotTapHuanDto());
    }

    //#region UX_JQuery

    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {
            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    dropDrapModal() {
        let modalContent: any = $('.ant-modal-content');
        modalContent.draggable({
            handle: '.ant-modal-header',
        });
    }

    ngAfterViewInit(): void {
        this.dropDrapModal();
        this.fullScreenClick();
    }
}
