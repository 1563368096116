import { Component, Injector, Input, OnInit } from '@angular/core';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { ModalComponentBase } from '@shared/common/modal-component-base';
import { ComboBoxEnumCode, QLTV_ChiSoDto, QLTV_ChiSoServiceProxy, LstDictionaryDto, KieuDuLieu, ComboBoxTableCode } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';

@Component({
    templateUrl: './chi-so-edit-modal.component.html',
})
export class QLTV_ChiSoEditModalComponent extends ModalComponentBase implements OnInit {
    formId = 'formQLTV_ChiSo';

    @Input() dataItem: QLTV_ChiSoDto;
    ComboBoxEnumCode = ComboBoxEnumCode;
    ComboBoxTableCode = ComboBoxTableCode;

    constructor(
        injector: Injector,
        private _dataService: QLTV_ChiSoServiceProxy) {
        super(injector);
    }

    ngOnInit(): void {
        if (this.dataItem.id > 0) {
            this.getInfoById(this.dataItem.id);
        } else {
            this.clearForm();
        }
    }

    getInfoById(id: number): void {
        abp.ui.setBusy();
        this._dataService
            .getById(id)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((res) => {
                this.dataItem = res;
            });
    }

    showDuLieuTu() {
        if (this.dataItem.kieuDuLieuTraLoiEnum == KieuDuLieu.DropdownList
            || this.dataItem.kieuDuLieuTraLoiEnum == KieuDuLieu.RadioList
            || this.dataItem.kieuDuLieuTraLoiEnum == KieuDuLieu.CheckboxList) {
            return true;
        } else {
            return false;
        }
    }

    save(): void {
        if (AppUtilityService.IsNullValidateForm(this.formId)) {
            abp.notify.error(this.l('_BanCanNhapDuDuLieuCacTruongCoDauSaoDo'));
            return;
        }
        abp.ui.setBusy();
        this._dataService
            .createOrUpdate(this.dataItem)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((result) => {
                if (result.isSuccessful) {
                    this.notify.success(this.l('SavedSuccessfully'));
                    if (this.isContinue) {
                        this.clearForm();
                    } else {
                        this.success(result.dataResult);
                    }
                } else {
                    this.notify.error(this.l('HasError'));
                }
            });
    }

    clearForm() {
        this.dataItem = Object.assign(new QLTV_ChiSoDto(), {
            isActive: true,
            kieuDuLieuTraLoiEnum: KieuDuLieu.RadioList
        });
    }
}
