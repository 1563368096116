<div nz-row [nzGutter]="[4,4]" [id]="formId">
    <div nz-col class="gutter-row" nzXs="24" nzMd="16">
        <nz-card nzTitle="Thông tin người dùng">

            <nz-form-item class="mb-1">
                <label nz-col nzXs="24" nzSm="8" class="font-weight-normal">Cơ sở</label>
                <nz-form-control nz-col nzXs="24" nzSm="16">
                    <co-so-combo [isAdd]="true" [(ngModel)]="user.coSoId" (eventChange)="onChangeCoSo($event?.level)"
                                 [disabled]="isView||isDisableCoSoId">
                    </co-so-combo>
                </nz-form-control>
            </nz-form-item>

            <hr />

            <nz-form-item class="mb-1">
                <label nz-col nzXs="24" nzSm="8" class="font-weight-normal">Tên người dùng<span class="text-red">*</span></label>
                <nz-form-control nz-col nzXs="24" nzSm="16">
                    <input nz-input [readonly]="isView" [(ngModel)]="user.name" maxlength="128" />
                    <validation-custom [sModel]="user.name"></validation-custom>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item class="mb-1">
                <label nz-col nzXs="24" nzSm="8" class="font-weight-normal">Email<span class="text-red">*</span></label>
                <nz-form-control nz-col nzXs="24" nzSm="16">
                    <input nz-input [readonly]="isView" [(ngModel)]="user.emailAddress" maxlength="128" />
                    <validation-custom [sType]="TYPE_VALIDATE.Email" [sModel]="user.emailAddress"></validation-custom>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item class="mb-1">
                <label nz-col nzXs="24" nzSm="8" class="font-weight-normal">Tên đăng nhập<span class="text-red">*</span> </label>
                <nz-form-control nz-col nzXs="24" nzSm="16">
                    <input nz-input [readonly]="isView||!canChangeUserName" [(ngModel)]="user.userName" maxlength="128" />
                    <validation-custom [sModel]="user.userName"></validation-custom>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="!user.id">
                <nz-form-control>
                    <label nz-checkbox name="SetRandomPassword" [(ngModel)]="setRandomPassword"
                           (ngModelChange)="user.password = null;userPasswordRepeat = null;setPassword = false">
                        {{"SetRandomPassword" |localize}}
                    </label>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="user.id && !setRandomPassword">
                <nz-form-control>
                    <label nz-checkbox name="setPassword" [(ngModel)]="setPassword"> Thay đổi mật khẩu</label>
                </nz-form-control>
            </nz-form-item>

            <ng-container *ngIf="(user.id && setPassword) || (!user.id && !setRandomPassword)">
                <nz-form-item class="mb-1">
                    <label nz-col nzXs="24" nzSm="8" class="font-weight-normal">
                        {{"Password" | localize}}<span class="text-red">*</span>
                    </label>
                    <nz-form-control nz-col nzXs="24" nzSm="16" [nzErrorTip]="passwordValidationTpl">
                        <nz-input-group [nzSuffix]="passwordSuffixTemplate">
                            <input nz-input [type]="passwordVisible ? 'text' : 'password'" #Password="ngModel" id="Password" name="Password"
                                   [(ngModel)]="user.password" (ngModelChange)="validatePassword(user.password)" placeholder="{{'Password' | localize}}"
                                   validateEqual="PasswordRepeat" reverse="true" [required]="!user.id && !setRandomPassword" maxlength="32"
                                   [requireDigit]="passwordComplexitySetting.requireDigit" [requireLowercase]="passwordComplexitySetting.requireLowercase"
                                   [requireUppercase]="passwordComplexitySetting.requireUppercase"
                                   [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric"
                                   [requiredLength]="passwordComplexitySetting.requiredLength">
                        </nz-input-group>
                        <ng-template #passwordSuffixTemplate>
                            <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
                        </ng-template>

                        <validation-custom [sType]="TYPE_VALIDATE.ValidRef" [sModel]="user.password" [sValidRef]="validationResult"></validation-custom>
                        <ng-template #passwordValidationTpl let-control>
                            <ul *ngIf="control.errors">
                                <li [hidden]="!control.errors.requireDigit">
                                    {{"PasswordComplexity_RequireDigit_Hint" | localize}}
                                </li>
                                <li [hidden]="!control.errors.requireLowercase">
                                    {{"PasswordComplexity_RequireLowercase_Hint" | localize}}
                                </li>
                                <li [hidden]="!control.errors.requireUppercase">
                                    {{"PasswordComplexity_RequireUppercase_Hint" | localize}}
                                </li>
                                <li [hidden]="!control.errors.requireNonAlphanumeric">
                                    {{"PasswordComplexity_RequireNonAlphanumeric_Hint" | localize}}
                                </li>
                                <li [hidden]="!control.errors.requiredLength">
                                    {{"PasswordComplexity_RequiredLength_Hint" |localize:passwordComplexitySetting.requiredLength}}
                                </li>
                            </ul>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <label nz-col nzXs="24" nzSm="8" class="font-weight-normal">
                        {{"PasswordRepeat" | localize}}<span class="text-red">*</span>
                    </label>
                    <nz-form-control nz-col nzXs="24" nzSm="16" [nzErrorTip]="passwordRepeatValidationTpl">
                        <nz-input-group [nzSuffix]="passwordRepeatSuffixTemplate">
                            <input nz-input name="PasswordRepeat" #PasswordRepeat="ngModel" [(ngModel)]="userPasswordRepeat"
                                   (ngModelChange)="validatePassword(userPasswordRepeat)" placeholder="{{'PasswordRepeat' | localize}}"
                                   [type]="passwordRepeatVisible ? 'text' : 'password'" validateEqual="Password" reverse="false"
                                   [required]="!user.id && !setRandomPassword" maxlength="32" [requireDigit]="passwordComplexitySetting.requireDigit"
                                   [requireLowercase]="passwordComplexitySetting.requireLowercase" [requireUppercase]="passwordComplexitySetting.requireUppercase"
                                   [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric"
                                   [requiredLength]="passwordComplexitySetting.requiredLength">
                        </nz-input-group>
                        <ng-template #passwordRepeatSuffixTemplate>
                            <i nz-icon [nzType]="passwordRepeatVisible ? 'eye-invisible' : 'eye'" (click)="passwordRepeatVisible = !passwordRepeatVisible"></i>
                        </ng-template>


                        <ng-template #passwordRepeatValidationTpl let-control>
                            <ul *ngIf="control.errors">
                                <li [hidden]="!control.errors.requireDigit">
                                    {{"PasswordComplexity_RequireDigit_Hint" | localize}}
                                </li>
                                <li [hidden]="!control.errors.requireLowercase">
                                    {{"PasswordComplexity_RequireLowercase_Hint" | localize}}
                                </li>
                                <li [hidden]="!control.errors.requireUppercase">
                                    {{"PasswordComplexity_RequireUppercase_Hint" | localize}}
                                </li>
                                <li [hidden]="!control.errors.requireNonAlphanumeric">
                                    {{"PasswordComplexity_RequireNonAlphanumeric_Hint" | localize}}
                                </li>
                                <li [hidden]="!control.errors.requiredLength">
                                    {{"PasswordComplexity_RequiredLength_Hint" |localize:passwordComplexitySetting.requiredLength}}
                                </li>
                                <li [hidden]="control.valid">{{"PasswordsDontMatch" | localize}}</li>
                            </ul>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </ng-container>

            <nz-form-item>
                <nz-form-control>
                    <label nz-checkbox [(ngModel)]="user.shouldChangePasswordOnNextLogin">{{"ShouldChangePasswordOnNextLogin" | localize}}</label>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-control>
                    <label nz-checkbox name="SendInfoAccountEmail" [(ngModel)]="sendInfoAccountEmail">Gửi email thông tin tài khoản</label>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-control>
                    <label nz-checkbox name="Active" [(ngModel)]="user.isActive">{{"Active" | localize}}</label>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-control>
                    <label nz-checkbox name="IsLockoutEnabled" [(ngModel)]="user.isLockoutEnabled">{{"IsLockoutEnabled" | localize}}</label>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="!currentUserIsAdmin1Module">
                <nz-form-control nz-col nzXs="24" nzSm="8">
                    <label nz-checkbox name="IsOnlyAdmin1Module" [(ngModel)]="user.isOnlyAdmin1Module">Giới hạn Admin1Module</label>
                    <span class="text-red" *ngIf="user.isOnlyAdmin1Module">*</span>
                </nz-form-control>
                <nz-form-control nz-col nzXs="24" nzSm="16" *ngIf="user.isOnlyAdmin1Module">
                    <enum-combo [EnumCode]="ComboBoxEnumCode.ModuleDangKy" [(ngModel)]="user.admin1ModuleId">
                    </enum-combo>
                    <validation-custom [sModel]="user.admin1ModuleId"></validation-custom>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="user.coSoId && user.level!=LEVEL.BO_Y_TE && user.level!=LEVEL.SO_Y_TE">
                <nz-form-control nz-col nzXs="24" nzSm="24">
                    <label nz-checkbox [(ngModel)]="user.isTaiKhoanPhongXetNghiem">Là tài khoản phòng xét nghiệm</label>
                    <span class="text-red" *ngIf="user.isTaiKhoanPhongXetNghiem">*</span>
                </nz-form-control>
                <nz-form-control nz-col nzXs="24" nzSm="24" *ngIf="user.isTaiKhoanPhongXetNghiem">
                    <table-combo [TableEnum]="ComboBoxTableCode.GetAllPhongXetNghiem" [CascaderId]="user.coSoId"
                                 [(ngModel)]="user.taiKhoanPhongXetNghiemId" [isAdd]="true" [isUpdate]="true">
                    </table-combo>
                    <validation-custom [sModel]="user.taiKhoanPhongXetNghiemId"></validation-custom>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="user.coSoId && user.level==LEVEL.BO_Y_TE">
                <nz-form-control nz-col nzXs="24" nzSm="24">
                    <label nz-checkbox [(ngModel)]="user.isChuyenGia">Là tài khoản chuyên gia bộ y tế</label>
                </nz-form-control>
            </nz-form-item>

        </nz-card>
    </div>
    <div nz-col class="gutter-row" nzXs="24" nzMd="8">
        <nz-card nzTitle="Phân quyền">

            <nz-row *ngIf="user.coSoId>0">
                <nz-col class="mb-md" nzSpan="24" *ngFor="let role of roles">
                    <label nz-checkbox [(ngModel)]="role.isAssigned" [name]="role.roleName" [disabled]="!checkShowRole(role.roleId)">
                        {{role.roleDisplayName}}
                    </label>
                </nz-col>
            </nz-row>
        </nz-card>
    </div>
</div>
<div *nzModalFooter>
    <label *ngIf="!user.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button type="button" nz-button nzType="primary" (click)="save()" [disabled]="!(user.coSoId>0)"><i nz-icon nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>
