import {
    Component,
    Input,
    OnInit,
    OnChanges,
    AfterViewInit,
    Output,
    EventEmitter,
    SimpleChanges,
    Injector,
    forwardRef
} from '@angular/core';
import { finalize } from 'rxjs/operators';
import { DepartmentItemDto, HAI_DepartmentServiceProxy, HAI_DepartmentPagingListRequest, PHAM_VI_DU_LIEU_HAI, NhomCoSo, PHAN_LOAI_TRANG_THAI } from '@shared/service-proxies/service-proxies';
import { LocalizationService } from 'abp-ng2-module';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DestroyRxjsService } from '@shared/destroy-rxjs.service';
import { AppUtilityService } from '../../../common/custom/utility.service';
import _ from 'lodash';

@Component({
    selector: 'hai-department-multiselect',
    templateUrl: './hai-department-multiselect.component.html',
    styleUrls: ['./hai-department-multiselect.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => HaiDepartmentMultiselectComponent),
            multi: true
        },
        DestroyRxjsService]
})
export class HaiDepartmentMultiselectComponent implements OnInit, OnChanges, ControlValueAccessor {
    localization: LocalizationService;
    control = new FormControl();
    isLoadInit: boolean = false;
    options: DepartmentItemDto[] = [];

    @Input() isGroup: boolean = true;
    @Input() multiMaxShowItem: number = 2;
    @Input() isMustSelectAllWhenLoad: boolean = true;

    //Filter
    @Input() organisationId: number;
    @Input() scope: number = PHAM_VI_DU_LIEU_HAI.QUOC_GIA;
    @Input() in_use: number = PHAN_LOAI_TRANG_THAI.DANG_HOAT_DONG;
    @Input() arrGroup: number[];
    //Ngày tham gia
    @Input() isThoiGianThamGia: boolean = true;
    @Input() fromDate: any;
    @Input() toDate: any;

    PHAM_VI_DU_LIEU_HAI = PHAM_VI_DU_LIEU_HAI;
    constructor(
        injector: Injector,
        private dataServices: HAI_DepartmentServiceProxy
    ) {
        this.localization = injector.get(LocalizationService);
    }

    ngAfterViewInit(): void {
        this.loadData();
    }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void {
        if ((changes.organisationId || changes.in_use || changes.scope || changes.arrGroup || changes.fromDate || changes.toDate) && this.isLoadInit) {
            this.loadData();
        }
    }

    onChange: any = () => {
    };
    onTouched: any = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(value: any): void {
        this.control.patchValue(value);
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.control.disable();
        } else {
            this.control.enable();
        }
    }

    selectAll() {
        if (this.options) {
            const values = this.options.map(it => it.id);
            this.control.patchValue(values);
            this.onChange(values);
        }
    }

    onChangeSelect() {
        this.onChange(this.control.value);
    }

    loadData(): void {

        const input = new HAI_DepartmentPagingListRequest();
        input.organisationId = this.organisationId || 0;
        input.languageCode = this.localization.currentLanguage?.name;
        input.scopeOfDepartment = this.scope;
        input.in_use = this.in_use;
        input.arrGroup = this.arrGroup;
        input.fromDate = this.fromDate;
        input.toDate = this.toDate;
        input.isThoiGianThamGia = this.isThoiGianThamGia;

        abp.ui.setBusy();
        this.dataServices
            .getListDepartment(input)
            .pipe(finalize(() => abp.ui.clearBusy()))
            .subscribe(res => {
                this.isLoadInit = true;
                if (res && res.dataResult) {
                    this.options = res.dataResult;
                    if (this.isMustSelectAllWhenLoad) {
                        setTimeout(() => {
                            this.selectAll();
                        });
                    }
                }
            });
    }

    onSearch(item) {
        //console.log('search called' + item.term);
    }

    testSearch(term: string, item) {
        //console.log(item);
        //console.log(term);

        const searchTxt = AppUtilityService.getFullTextSearch(term);
        if (AppUtilityService.isNotNull(searchTxt) && item) {

            //item.name
            const ftsVietTat = AppUtilityService.searchVietTat(item.name) || "";
            const checkVietTat = ftsVietTat.indexOf(searchTxt) > -1;
            const ftsFullTextSearch = AppUtilityService.getFullTextSearch(item.name) || "";
            const checkFullTextSearch = ftsFullTextSearch.indexOf(searchTxt) > -1;

            //item.organisationName
            const ftsVietTat1 = AppUtilityService.searchVietTat(item.organisationName) || "";
            const checkVietTat1 = ftsVietTat1.indexOf(searchTxt) > -1;
            const ftsFullTextSearch1 = AppUtilityService.getFullTextSearch(item.organisationName) || "";
            const checkFullTextSearch1 = ftsFullTextSearch1.indexOf(searchTxt) > -1;

            return checkFullTextSearch || checkVietTat || checkFullTextSearch1 || checkVietTat1;
        }

        return item.name.startsWith(term);
    }
}
