import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as $ from 'jquery';

@Component({
    selector: 'view-pdf',
    templateUrl: './view-pdf.component.html',
    styleUrls: ['./view-pdf.component.scss'],
})
export class ViewPdfKetQuaComponent implements OnInit {
    @Input() path;
    constructor(protected sanitizer: DomSanitizer) { }

    ngOnInit(): void {
    }

    getPath() {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.path)
    }

    //#region UX_JQuery

    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {
            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    dropDrapModal() {
        let modalContent: any = $('.ant-modal-content');
        modalContent.draggable({
            handle: '.ant-modal-header',
        });
    }

    ngAfterViewInit(): void {
        this.dropDrapModal();
        this.fullScreenClick();
    }
    //#endregion
}
