import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { CodeSystemConst } from '@app/shared/common/AppConsts';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { TYPE_VALIDATE } from '@shared/AppEnums';
import { ModalComponentBase } from '@shared/common/modal-component-base';
import {
    ComboBoxEnumCode,
    ComboBoxTableCode,
    CoSoDto,
    CreateOrUpdateUserInput,
    LEVEL,
    PasswordComplexitySetting,
    ProfileServiceProxy,
    UserEditDto,
    UserRoleDto,
    UserServiceProxy,
} from '@shared/service-proxies/service-proxies';
import * as $ from 'jquery';
import { filter as _filter, map as _map } from 'lodash-es';
import { finalize } from 'rxjs/operators';
import { AppSessionService } from '../../../../../shared/common/session/app-session.service';
import { PermissionCheckerService } from 'abp-ng2-module';

@Component({
    templateUrl: './tai-khoan-nguoi-dung-edit-modal.component.html',
    styleUrls: ['./tai-khoan-nguoi-dung-edit-modal.component.scss']
})
export class TaiKhoanNguoiDungEditModalComponent extends ModalComponentBase implements OnInit, AfterViewInit {
    formId = 'formValidateTaiKhoan';
    ComboBoxTableCode = ComboBoxTableCode;
    LEVEL = LEVEL;

    userId?: number;
    canChangeUserName = true;
    sendActivationEmail = false;
    sendInfoAccountEmail = false;
    setRandomPassword = false;
    setPassword = false;
    memberedOrganizationUnits: string[];
    isLockoutEnabled: boolean = this.setting.getBoolean('Abp.Zero.UserManagement.UserLockOut.IsEnabled');
    passwordComplexitySetting: PasswordComplexitySetting = new PasswordComplexitySetting();
    passwordVisible = false;
    passwordRepeatVisible = false;
    user: UserEditDto = new UserEditDto();
    roles: UserRoleDto[];
    userPasswordRepeat = '';
    CodeSystemConst = CodeSystemConst;
    TYPE_VALIDATE = TYPE_VALIDATE;
    validationResult: any;

    //Custom
    isQuanTriModule: boolean = false;
    ComboBoxEnumCode = ComboBoxEnumCode;
    currentUserIsAdmin1Module: boolean = false;
    isDisableCoSoId: boolean = false;

    constructor(
        injector: Injector,
        private _userService: UserServiceProxy,
        private _profileService: ProfileServiceProxy,
        private _sessionService: AppSessionService,
        private _permissionChecker: PermissionCheckerService,
    ) {
        super(injector);
    }

    ngOnInit(): void {

        console.log(this._sessionService.user.admin1Module_ArrRoleId, 'admin1Module_ArrRoleId');

        this.isQuanTriModule = this._permissionChecker.isGranted('Pages.QuanLyCoSoYTe.QuyenQuanTriModule');

        this.getInfoById();

        this._profileService.getPasswordComplexitySetting().subscribe((passwordComplexityResult) => {
            this.passwordComplexitySetting = passwordComplexityResult.setting;
        });

        if (this._sessionService.user) {
            this.currentUserIsAdmin1Module = this._sessionService.user.isOnlyAdmin1Module;

            if (!this.isQuanTriModule) {
                if (!this.currentUserIsAdmin1Module) {
                    this.isDisableCoSoId = true;
                }
            }
            this.isDisableCoSoId = false;
        }
    }

    getInfoById(): void {
        console.log(this.userId, 'this.userId');
        abp.ui.setBusy();
        this._userService.getUserForEdit(this.userId)
            .pipe(finalize(() => { abp.ui.clearBusy(); }))
            .subscribe((result) => {
                this.user = result.user;
                this.canChangeUserName = this.user.userName !== AppConsts.userManagement.defaultAdminUserName;
                this.roles = result.roles;

                if (!this.user.coSoId) {
                    this.user.coSoId = this._sessionService.user.coSoId;
                }

                if (this._sessionService.user && !this.user.id) {
                    this.onChangeCoSo(this._sessionService.user.level);
                }
            });
    }

    checkShowRole(roleId: number) {

        if (this._sessionService.user.isOnlyAdmin1Module) {
            if (this._sessionService.user.admin1Module_ArrRoleId) {
                let _roleId = this._sessionService.user.admin1Module_ArrRoleId.findIndex(item => {
                    return item == roleId;
                });
                if (_roleId != -1) {
                    return true;
                }
                return false;
            }
            return true;
        }
        return true;
    }

    save(): void {
        this.user.surname = '.';

        if (AppUtilityService.IsNullValidateForm(this.formId)) {
            abp.notify.error(this.l('_BanCanNhapDuDuLieuCacTruongCoDauSaoDo'));
            return;
        }
        if (this.user.password != this.userPasswordRepeat && this.setPassword) {
            abp.notify.error('Mật khẩu nhắc lại không đúng');
            return;
        }
        const input = new CreateOrUpdateUserInput();
        input.user = this.user;
        input.setRandomPassword = this.setRandomPassword;
        input.sendActivationEmail = this.sendActivationEmail;
        input.sendInfoAccountEmail = this.sendInfoAccountEmail;
        input.assignedRoleNames = _map(_filter(this.roles, { isAssigned: true }), (role) => role.roleName);
        abp.ui.setBusy();
        this._userService
            .createOrUpdateUser(input)
            .pipe(finalize(() => abp.ui.clearBusy()))
            .subscribe((res) => {
                if (res.isSuccessful) {
                    abp.notify.success(this.l('SavedSuccessfully'));
                    this.userPasswordRepeat = '';
                    this.success(res.dataResult);
                } else {
                    abp.notify.error(this.l('HasError'));
                }
            });
    }

    onChangeCoSo(level?: number) {

        this.user.level = level;
        if (level) {
            this._userService.getAllRoleNamesByLevel(level).subscribe((result) => {
                this.roles = result;
            });
        }
        else {
            this.roles = [];
        }
    }

    validatePassword(givenPassword) {
        let validationResult = {
            isError: false,
            messages: '',
        };

        const requireDigit = this.passwordComplexitySetting.requireDigit;
        if (requireDigit && givenPassword && !/[0-9]/.test(givenPassword)) {
            validationResult.isError = true;
        }

        const requireUppercase = this.passwordComplexitySetting.requireUppercase;
        if (requireUppercase && givenPassword && !/[A-Z]/.test(givenPassword)) {
            validationResult.isError = true;
        }

        const requireLowercase = this.passwordComplexitySetting.requireLowercase;
        if (requireLowercase && givenPassword && !/[a-z]/.test(givenPassword)) {
            validationResult.isError = true;
        }

        const requiredLength = this.passwordComplexitySetting.requiredLength;
        if (requiredLength && givenPassword && givenPassword.length < requiredLength) {
            validationResult.isError = true;
        }

        const requireNonAlphanumeric = this.passwordComplexitySetting.requireNonAlphanumeric;
        if (requireNonAlphanumeric && givenPassword && /^[0-9a-zA-Z]+$/.test(givenPassword)) {
            validationResult.isError = true;
        }

        this.validationResult = validationResult;
    }

    //#region UX-JQuery
    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {
            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    dropDrapModal() {
        let modalContent: any = $('.ant-modal-content');
        modalContent.draggable({
            handle: '.ant-modal-header',
        });
    }

    ngAfterViewInit(): void {
        this.dropDrapModal();
        this.fullScreenClick();
    }
    //#endregion
}
