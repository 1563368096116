import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { PagedListingComponentBase, PagedRequestDto } from '@shared/common/paged-listing-component-base';
import { CapDoDonViHanhChinh, CoSoPagingListRequest, ComboBoxEnumCode, ComboBoxTableCode, LEVEL, ThongTinCoSoServiceProxy } from '@shared/service-proxies/service-proxies';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { finalize } from 'rxjs/operators';

@Component({
    templateUrl: './co-so-select-search-modal.component.html',
})
export class CoSoSelectSearchModalComponent extends PagedListingComponentBase<any> implements OnInit {
    @Input() listOfSelectedValue: number[];
    @Input() isMulti: boolean = false;
    @Input() isCoQuanChuQuan?: boolean;
    @Input() isGetAll?: boolean = true;
    @Input() pId;

    //Custom
    @Input() maTinh;
    @Input() maHuyen;
    @Input() maXa;
    @Input() arrLevel;
    @Input() isAMR;
    @Input() isHAI;


    ComboBoxTableCode = ComboBoxTableCode;
    ComboBoxEnumCode = ComboBoxEnumCode;
    CapDoDonViHanhChinh = CapDoDonViHanhChinh;
    coSoId = null;
    constructor(
        injector: Injector,
        private _dataService: ThongTinCoSoServiceProxy,
        private modal: NzModalRef,
        private cdr: ChangeDetectorRef) {
        super(injector);
    }

    ngOnInit(): void {
        this.searchDto.arrLevelSearch = [LEVEL.TRUNG_TAM_Y_TE_HAI_CHUC_NANG, LEVEL.BENH_VIEN]
        this.refresh();
    }

    clear() {
        this.searchDto = {};
        this.refresh();
    }

    protected fetchDataList(request: PagedRequestDto, pageNumber: number, finishedCallback: () => void): void {
        const input: CoSoPagingListRequest = new CoSoPagingListRequest();
        input.maxResultCount = request.maxResultCount;
        input.skipCount = request.skipCount;
        input.sorting = request.sorting;
        input.isCoQuanChuQuan = this.isCoQuanChuQuan;
        input.isGetAll = this.isGetAll;

        input.maTinh = this.maTinh;
        input.maHuyen = this.maHuyen;
        input.maXa = this.maXa;
        input.arrLevel = this.arrLevel;
        input.isAMR = this.isAMR;
        input.isHAI = this.isHAI;

        let rep: any = {
            ...input,
            ...this.searchDto,
            coSoId: this.coSoId,
            pId: this.pId
        };
        this._dataService
            .getPagingList(rep)
            .pipe(finalize(finishedCallback))
            .subscribe((result) => {
                this.dataList = result.items;
                this.dataList.forEach((item) => {
                    if (this.listOfSelectedValue && this.listOfSelectedValue.length > 0) {
                        let checkId = this.listOfSelectedValue.findIndex((x) => x == item.id);
                        if (checkId > -1) {
                            item.checked = true;
                        }
                    }
                });

                this.showPaging(result);
                this.cdr.detectChanges();
            });
    }

    save() {
        this.modal.close(this.selectedDataItems);
    }

    close() {
        this.modal.destroy();
    }

    //#region UX-JQuery

    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {
            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    dropDrapModal() {
        let modalContent: any = $('.ant-modal-content');
        modalContent.draggable({
            handle: '.ant-modal-header',
        });
    }

    ngAfterViewInit(): void {
        this.dropDrapModal();
        this.fullScreenClick();
    }
    //#endregion
}
