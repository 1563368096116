import { ChangeDetectorRef, Component, Injector, Input, OnInit, SimpleChanges } from "@angular/core";
import { ModalPagedListingComponentBase } from "@shared/common/modal-paged-listing-component-base";
import { PagedRequestDto } from "@shared/common/paged-listing-component-base";
import { BCT_DanhMucChiSoDto, BCT_DanhMucChiSoPagingListRequest, BCT_DanhMucChiSoServiceProxy } from "@shared/service-proxies/service-proxies";
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { finalize, map } from "rxjs/operators";

@Component({
    templateUrl: './chi-so-bao-cao-add.component.html'
})
export class ChiSoBaoCaoAddComponent extends ModalPagedListingComponentBase<BCT_DanhMucChiSoDto> implements OnInit {
    formId = 'formValidateMaDVKTXetNghiem';
    @Input() dataItem: BCT_DanhMucChiSoDto;
    @Input() loaiDotBaoCaoEnum: number;
    @Input() listOfSelectedValue;

    listChoose = [];

    constructor(
        injector: Injector,
        private _BCT_DanhMucChiSoServiceProxy: BCT_DanhMucChiSoServiceProxy,
    ) {
        super(injector);
    }

    protected getDataList(request: PagedRequestDto, pageNumber: number, finishedCallback: () => void): void {
        const input: BCT_DanhMucChiSoPagingListRequest = new BCT_DanhMucChiSoPagingListRequest();
        input.maxResultCount = request.maxResultCount;
        input.skipCount = request.skipCount;
        input.sorting = request.sorting;
        input.loaiDotBaoCaoEnum = this.loaiDotBaoCaoEnum;
        input.listIgnoreId = this.listOfSelectedValue;
        this._BCT_DanhMucChiSoServiceProxy

            .getPagingList(input)

            .pipe(
                map(val => {
                    val.items.forEach(it => {
                        let checkChoose = this.listChoose.findIndex(v => v.id == it.id) !== -1;
                        if (checkChoose) {
                            (it as any).checked = true;
                        }
                    });
                    return { ...val }
                }),
                finalize(finishedCallback))
            .subscribe((result) => {
                this.dataList = result.items;
                this.showPaging(result);
            });
    }

    ngOnInit(): void {
        this.refresh();
    }

    save(){
        if (AppUtilityService.IsNullValidateForm(this.formId)) {
            abp.notify.error(this.l('_BanCanNhapDuDuLieuCacTruongCoDauSaoDo'));
            return;
        }
        abp.ui.setBusy();
        this._BCT_DanhMucChiSoServiceProxy
            .createOrUpdate(this.dataItem)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((result) => {
                if (result.isSuccessful) {
                    this.notify.success(this.l('SavedSuccessfully'));
                    if (this.isContinue) {
                        this.clearForm();
                    } else {
                        this.success(result.dataResult);
                    }
                } else {
                    this.notify.error(result.errorMessage);
                }
            });
    }
    clearForm() {
        this.dataItem = Object.assign(new BCT_DanhMucChiSoDto());
    }

    changeChooseChiSoBaoCao($event) {
        this.dataList.forEach(item => {
            let checkChoose = this.listChoose.findIndex(v => v.id == item.id);
            let checkEvent = $event.findIndex(v=>v.id == item.id);

            if((checkEvent !== -1) && (checkChoose === -1)){
              this.listChoose.push($event[checkEvent])
            }

            if((checkEvent ===-1 ) && (checkChoose !== -1)) {
              this.listChoose.splice(checkChoose, 1)
            }
          })

      }
}
