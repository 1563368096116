import { Component, Injector, Input, OnInit } from '@angular/core';
import { ModalComponentBase } from '@shared/common/modal-component-base';
import { ICD10Dto, ICD10ServiceProxy } from '@shared/service-proxies/service-proxies';
import * as $ from 'jquery';
import { finalize } from '@node_modules/rxjs/operators';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';

@Component({
    selector: 'app-10-edit-modal',
    templateUrl: './icd10-edit-modal.component.html',
    styleUrls: ['./icd10-edit-modal.component.scss']
})
export class ICD10EditModalComponent extends ModalComponentBase implements OnInit {
    formId = 'formValidateICD10';
    @Input() dataItem: ICD10Dto;
    @Input() isView = false;
    constructor(
        injector: Injector,
        private _ICD10ServiceProxy: ICD10ServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit() {
        if (this.dataItem.id > 0) {
            this.getInfoById(this.dataItem.id);
        } 
    }

    getInfoById(id): void {
        abp.ui.setBusy();
        this._ICD10ServiceProxy
            .getById(id)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((response) => {
                this.dataItem = response;
            });
    }

    save(){
        if (AppUtilityService.IsNullValidateForm(this.formId)) {
            abp.notify.error(this.l('_BanCanNhapDuDuLieuCacTruongCoDauSaoDo'));
            return;
        }
        abp.ui.setBusy();
        this._ICD10ServiceProxy
            .createOrUpdate(this.dataItem)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((result) => {
                if (result.isSuccessful) {
                    this.notify.success(this.l('SavedSuccessfully'));
                    if (this.isContinue) {
                        this.clearForm();
                    } else {
                        this.success(result.dataResult);
                    }
                } else {
                    this.notify.error(result.errorMessage);
                }
            });
    }

    clearForm() {
        this.dataItem = Object.assign(new ICD10Dto());
    }


    //#region UX_JQuery

    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {
            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    dropDrapModal() {
        let modalContent: any = $('.ant-modal-content');
        modalContent.draggable({
            handle: '.ant-modal-header',
        });
    }

    ngAfterViewInit(): void {
        this.dropDrapModal();
        this.fullScreenClick();
    }
    //#endregion

}
