import { Component, Injector, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ModalComponentBase } from "@shared/common/modal-component-base";
import { DangKyTaiKhoanDto, DangKyTaiKhoanServiceProxy } from "@shared/service-proxies/service-proxies";
import * as $ from 'jquery';
import { finalize } from 'rxjs/operators';
import { TYPE_VALIDATE } from '@shared/AppEnums';
import { AppUtilityService } from '../../common/custom/utility.service';

@Component({
    templateUrl: './dang-ky-bo-sung-modal.component.html',
    styleUrls: ['./dang-ky-bo-sung-modal.component.less']
})
export class DangKyBoSungModalComponent extends ModalComponentBase implements OnInit {

    formId = 'formValidateDangKy';
    TYPE_VALIDATE = TYPE_VALIDATE;

    dataItem = new DangKyTaiKhoanDto();

    constructor(
        injector: Injector,
        private _dangKyTaiKhoanServiceProxy: DangKyTaiKhoanServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit(): void {

        abp.ui.setBusy();
        this._dangKyTaiKhoanServiceProxy
            .initDangKyBoSung()
            .pipe(finalize(() => {
                abp.ui.clearBusy();
            })
            )
            .subscribe((result) => {
                console.log(result, 'result');
                this.dataItem = result.dataResult;
            });
    }

    guiDangKy() {
        if (AppUtilityService.IsNullValidateForm(this.formId)) {
            abp.notify.error(this.l('_BanCanNhapDuDuLieuCacTruongCoDauSaoDo'));
            return;
        }

        abp.ui.setBusy();
        this._dangKyTaiKhoanServiceProxy
            .dangKyBoSungTinhNang(this.dataItem)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((result) => {
                this.notify.success('Đăng ký thành công!');
                this.success();
            });
    }

    //#region UX_JQuery

    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {
            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    dropDrapModal() {
        let modalContent: any = $('.ant-modal-content');
        modalContent.draggable({
            handle: '.ant-modal-header',
        });
    }

    ngAfterViewInit(): void {
        this.dropDrapModal();
        this.fullScreenClick();
    }
    //#endregion
}
