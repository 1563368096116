import { Component, OnInit, ElementRef, Renderer2, Inject, OnDestroy, ComponentFactoryResolver, ViewContainerRef, ViewChild, NgZone } from '@angular/core';
import { SignalRHelper } from '@shared/helpers/SignalRHelper';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Injector } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, NavigationError, NavigationCancel, RouteConfigLoadStart } from '@angular/router';
import { TitleService, SettingsService, ScrollService } from '@delon/theme';
import { DOCUMENT } from '@angular/common';
import { updateHostClass } from '@delon/util';
import { Subject, Subscription } from 'rxjs';
import { environment } from '@env/environment';
import { SettingDrawerComponent } from '@app/shared/layout/setting-drawer/setting-drawer.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { AppNavigationService } from '@app/shared/layout/nav/app-navigation.service';
import { debounceTime, distinctUntilChanged, takeUntil } from '@node_modules/rxjs/internal/operators';
import { ReuseItem } from '@delon/abc/reuse-tab';
import { LoadingService } from '@delon/abc/loading';
import { SessionServiceProxy } from '../shared/service-proxies/service-proxies';
import { en_US, NzI18nService, vi_VN } from 'ng-zorro-antd/i18n';

@Component({
    selector: 'app-app',
    templateUrl: './app.component.html',
})

export class AppComponent extends AppComponentBase implements OnInit, AfterViewInit, OnDestroy {
    private notify$: Subscription;
    isFetching = false;
    $isFetching = new Subject<boolean>();
    $destroy = new Subject<boolean>();
    @ViewChild('settingHost', { read: ViewContainerRef, static: true })
    settingHost: ViewContainerRef;
    installationMode = true;
    isToggle = true;
    IsSessionTimeOutEnabled: boolean = this.setting.getBoolean('App.UserManagement.SessionTimeOut.IsEnabled') && this.appSession.userId != null;

    constructor(
        injector: Injector,
        private settings: SettingsService,
        private router: Router,
        scroll: ScrollService,
        //private titleSrv: TitleService,
        private el: ElementRef,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private doc: any,
        public _zone: NgZone,
        private resolver: ComponentFactoryResolver,
        private _appNavigationService: AppNavigationService,
        private loadingSrv: LoadingService,
        private _sessionService: SessionServiceProxy
    ) {
        super(injector);

        // scroll to top in change page
        router.events.subscribe(evt => {
            if (!this.isFetching && evt instanceof RouteConfigLoadStart) {
                this.loadingSrv.open({ type: 'spin', text: this.l('_VuiLongDoiTrongGiayLat') });
            }
            if (evt instanceof NavigationError || evt instanceof NavigationCancel) {
                this.loadingSrv.close();
                return;
            }
            scroll.scrollToTop();
            this.loadingSrv.close();
        });
    }

    private setClass() {
        const { el, doc, renderer, settings } = this;
        const layout = settings.layout;
        updateHostClass(
            el.nativeElement,
            renderer,
            {
                ['alain-default']: true,
                [`alain-default__fixed`]: layout.fixed,
                [`alain-default__collapsed`]: layout.collapsed,
            },
            true,
        );

        doc.body.classList[layout.colorWeak ? 'add' : 'remove']('color-weak');
    }

    ngOnInit(): void {
        this.registerEvents();
        this._appNavigationService.mapToNgAlainMenu();
        this.installationMode = UrlHelper.isInstallUrl(location.href);

        if (this.appSession.application) {
            // SignalRHelper.initSignalR(() => {
            //     this._zone.runOutsideAngular(() => {
            //         abp.signalr.connect();
            //     });
            // });
        }

        this.notify$ = this.settings.notify.subscribe(() => this.setClass());
        this.setClass();

        //Load DB2 và DB3
        this._sessionService
            .pingDb2()
            .subscribe((result) => {
            });
    }

    registerEvents() {
        abp.event.on('abp.ui.setBusy', (message = this.l('_VuiLongDoiTrongGiayLat')) => {
            this.loadingSrv.open({ type: 'spin', text: message });
        });
        abp.event.on('abp.ui.clearBusy', () => {
            this.loadingSrv.close();
        });
    }

    tabChange($event: ReuseItem) {
        console.log('$event', $event);
    }

    tabClose($event: ReuseItem) {
        console.log('$event tabClose', $event);
    }

    ngAfterViewInit(): void {
        // Setting componet for only developer
        // if (!environment.production) {
        //     setTimeout(() => {
        //         const settingFactory = this.resolver.resolveComponentFactory(
        //             SettingDrawerComponent,
        //         );
        //         this.settingHost.createComponent(settingFactory);
        //     }, 22);
        // }
    }

    ngOnDestroy() {
        this.notify$.unsubscribe();
    }
}
