<div class="oda-body-main" [id]="formId">
    <div nz-row>
        <div nz-col class="gutter-row ord-form-control" [nzSm]="12" [nzXs]="12">
            <label class="font-weight-normal">Đợt báo cáo <span class="text-red">*</span></label>
            <nz-form-control nzErrorTip="Giá trị bắt buộc!">
                <input nz-input [(ngModel)]="dataItem.ten">
                <validation-custom [sModel]="dataItem.ten"></validation-custom>
            </nz-form-control>
        </div>
        <div nz-col class="gutter-row ord-form-control" [nzSm]="6" [nzXs]="6">
            <label class="font-weight-normal">Ngày bắt đầu <span class="text-red">*</span></label>
            <nz-form-control nzErrorTip="Giá trị bắt buộc!">
                <ora-date-picker [(ngModel)]="dataItem.ngayBatDau" (ngModelChange)="calcListNgayBaoCao()"
                                 [disabledDate]="disabledDate" [disabled]="isView">
                </ora-date-picker>
                <validation-custom [sModel]="dataItem.ngayBatDau"></validation-custom>
            </nz-form-control>
        </div>
        <div nz-col class="gutter-row ord-form-control" [nzSm]="6" [nzXs]="6">
            <label class="font-weight-normal">Ngày kết thúc <span class="text-red">*</span></label>
            <nz-form-control nzErrorTip="Giá trị bắt buộc!">
                <ora-date-picker [(ngModel)]="dataItem.ngayKetThuc" (ngModelChange)="calcListNgayBaoCao()"
                                 [disabledDate]="disabledDate" [disabled]="isView">
                </ora-date-picker>
                <validation-custom [sModel]="dataItem.ngayKetThuc"></validation-custom>
            </nz-form-control>
        </div>
    </div>
    <div nz-row>
        <div nz-col class="gutter-row ord-form-control" [nzSm]="12" [nzXs]="12">
            <label class="font-weight-normal">Ghi chú</label>
            <nz-form-control nzErrorTip="Giá trị bắt buộc!">
                <input nz-input [(ngModel)]="dataItem.ghiChu">
            </nz-form-control>
        </div>
        <div nz-col class="gutter-row ord-form-control" [nzSm]="6" [nzXs]="12">
            <label class="font-weight-normal">Năm báo cáo <span class="text-red">*</span></label>
            <nz-form-control nzErrorTip="Giá trị bắt buộc!">
                <input nz-input [(ngModel)]="dataItem.namBaoCao">
                <validation-custom [sModel]="dataItem.namBaoCao"></validation-custom>
            </nz-form-control>
        </div>
        <div nz-col class="gutter-row ord-form-control" [nzSm]="6" [nzXs]="12">
            <label class="font-weight-normal">Loại báo cáo <span class="text-red">*</span></label>
            <nz-form-control nzErrorTip="Giá trị bắt buộc!">
                <enum-combo (eventChange)="changeDotBaoCao($event)" [EnumCode]="ComboBoxEnumCode.LoaiBaoCaoTet" [(ngModel)]="dataItem.loaiDotBaoCaoEnum"
                            [disabled]="isView">
                </enum-combo>
                <validation-custom [sModel]="dataItem.loaiDotBaoCaoEnum"></validation-custom>
            </nz-form-control>
        </div>
    </div>
    <nz-card class="ora-custom" style="margin-top: 10px;">
        <div nz-col class="gutter-row ord-form-control" [nzSm]="24" [nzXs]="24">
            <nz-tabset nzType="card" [(nzSelectedIndex)]="tabIndex">
                <nz-tab [nzForceRender]="true" style="margin-bottom: 5px;" nzTitle="Cấu hình lịch âm">

                    <nz-table nzTemplateMode #nzTablelstNgayBaoCao [nzScroll]="{ y: '300px' }" nzBordered
                              nzTableLayout="fixed" *ngIf="(dataItem.ngayBatDau && dataItem.ngayKetThuc) else elseLichAm">
                        <thead>
                            <tr>
                                <th nzAlign="center" nzWidth="50px" style="z-index: 1;" nzLeft>STT</th>
                                <th nzAlign="center" nzWidth="150px">Ngày dương</th>
                                <th nzAlign="center" nzWidth="150px">Ngày âm</th>
                                <th nzAlign="center" nzWidth="200px">Text hiển thị</th>
                                <th nzAlign="center" nzWidth="150px">Số so sánh <span class="text-red">*</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of cauHinhLichAm;index as idx">
                                <td nzAlign="center" style="z-index: 1;" nzLeft>
                                    {{idx+1}}
                                </td>
                                <td nzAlign="center">{{item.sNgayDuong}}</td>
                                <td nzAlign="center">
                                    <input nz-input [(ngModel)]="item.sNgayAm">
                                </td>
                                <td>
                                    <input nz-input [(ngModel)]="item.textHienThi">
                                </td>
                                <td>
                                    <input nz-input [(ngModel)]="item.compareNumber" type="number">
                                    <validation-custom [sModel]="item.compareNumber"></validation-custom>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                    <ng-template #elseLichAm>
                        <nz-alert nzType="info"
                                  nzMessage="Chọn ngày bắt đầu, ngày kết thúc để bắt đầu cầu hình! " nzShowIcon></nz-alert>
                    </ng-template>
                </nz-tab>
                <nz-tab [nzForceRender]="true" nzTitle="Cấu hình chỉ số">
                    <i *ngIf="(dataItem.loaiDotBaoCaoEnum)" class="font-weight-normal"> <span class="text-red">*</span>Nhập Sắp xếp bằng 0 với chỉ số hệ thống tự tính</i>
                    <button *ngIf="(dataItem.loaiDotBaoCaoEnum)" class="float-right" nz-button nzType="primary" nzSize="outline" (click)="themChiSo()" style="margin-bottom: 10px;">
                        <i nz-icon nzType="plus"></i> Thêm chỉ số
                    </button>
                    <nz-table nzTemplateMode [nzScroll]="{ y: '300px' }" nzBordered nzTableLayout="fixed" *ngIf="(dataItem.loaiDotBaoCaoEnum) else elseLoaiDotBaoCaoEnum">
                        <thead>
                            <tr>
                                <th nzAlign="center" nzWidth="50px" style="z-index: 1;" nzLeft>STT</th>
                                <!-- <th nzAlign="center" nzWidth="100px">Danh mục</th> -->
                                <th [nzWidth]="getWidthMobile(250)">Chỉ số</th>
                                <th nzAlign="center" nzWidth="100px">Sắp xếp</th>
                                <th nzAlign="center" nzWidth="100px">Thao tác</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of cauHinhChiSo;index as idx">
                                <td nzAlign="center" style="z-index: 1;" nzLeft>
                                    {{idx+1}}
                                </td>
                                <td>
                                    <table-combo [TableEnum]="ComboBoxTableCode.GetAllChiSoBaoCaoTet"
                                                 [isAdd]="true" (eventChange)="item.tenChiSo=$event?.ten"
                                                 [(ngModel)]="item.chiSoId" [disabled]="true">
                                    </table-combo>
                                </td>

                                <td nzAlign="center">
                                    <input nz-input [(ngModel)]="item.sortOrder" type="number">
                                </td>
                                <td nzAlign="center">
                                    <button nz-button nzDanger nzSize="small" (click)="xoaChiSo(idx)">
                                        <i nz-icon nzType="delete"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                    <ng-template #elseLoaiDotBaoCaoEnum>
                        <nz-alert nzType="info"
                                  nzMessage="Vui lòng chọn loại báo cáo!" nzShowIcon></nz-alert>
                    </ng-template>
                </nz-tab>
            </nz-tabset>
        </div>
    </nz-card>
</div>
<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button type="button" nz-button nzType="primary" (click)="save()"><i nz-icon nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>

