import {
    Component,
    Input,
    OnInit,
    OnChanges,
    AfterViewInit,
    Output,
    EventEmitter,
    SimpleChanges,
    Injector,
    forwardRef
} from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ComboItemDto } from '@shared/service-proxies/service-proxies';
import { LocalizationService } from 'abp-ng2-module';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DestroyRxjsService } from '@shared/destroy-rxjs.service';
import { AppUtilityService } from '../../../common/custom/utility.service';
import { BaseUXService } from '../../../services/base-ux.service';

@Component({
    selector: 'combo-multiselect',
    templateUrl: './combo-multiselect.component.html',
    styleUrls: ['./combo-multiselect.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ComboMultiselectComponent),
            multi: true
        },
        DestroyRxjsService]
})
export class ComboMultiselectComponent extends BaseUXService implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor {
    localization: LocalizationService;
    control = new FormControl();
    isLoadInit: boolean = false;
    options: ComboItemDto[] = [];
    @Input() placeHolder = '-' + this.l('_Chon') + '-';

    notFoundText = '';

    @Input() colName: string = 'name';
    @Input() colId: string = 'id';
    @Input() set sourceDto(dataList: any[]) {
        if (dataList) {
            let v = dataList.map(item => {
                var obj = new ComboItemDto();
                obj.id = item[this.colId];
                obj.name = item[this.colName];
                return obj;
            });
            this.source = v;

            if (this.isMustSelectAllWhenLoad) {
                setTimeout(() => {
                    this.selectAll();
                });
            }
        }
    }

    @Input() set source(v: ComboItemDto[]) {
        this.options = v;

        if (this.isMustSelectAllWhenLoad) {
            setTimeout(() => {
                this.selectAll();
            });
        }
    }

    @Output() eventChange = new EventEmitter();

    @Input() isGroup: boolean = false;
    @Input() multiMaxShowItem: number = 2;
    @Input() isMustSelectAllWhenLoad: boolean = false;

    constructor(
        injector: Injector
    ) {
        super();
        this.localization = injector.get(LocalizationService);
    }

    ngAfterViewInit(): void {
        if (this.isMustSelectAllWhenLoad) {
            setTimeout(() => {
                this.selectAll();
            });
        }
    }

    ngOnInit(): void {

        this.notFoundText = this.localization.currentLanguage?.name == 'en' ? 'No items found' : 'Không có dữ liệu';
    }

    ngOnChanges(changes: SimpleChanges): void {
        //if (changes.options) {
        //    if (this.isMustSelectAllWhenLoad) {
        //        setTimeout(() => {
        //            this.selectAll();
        //        });
        //    }
        //}
    }

    onChange: any = () => {
    };
    onTouched: any = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(value: any): void {
        this.control.patchValue(value);
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.control.disable();
        } else {
            this.control.enable();
        }
    }

    selectAll() {
        if (this.options) {
            const values = this.options.map(it => it.id);
            this.control.patchValue(values);
            this.onChange(values);

            if (this.eventChange) this.eventChange.emit(values);
        }
    }

    clearAll() {
        if (this.eventChange) this.eventChange.emit([]);
    }

    onChangeSelect() {
        this.onChange(this.control.value);

        if (this.control.value) {
            this.eventChange.emit(this.control.value);
        }
        else {
            this.eventChange.emit(undefined);
        }
    }

    onSearch(item) {
        //console.log('search called' + item.term);
    }

    testSearch(term: string, item) {
        //console.log(item);
        //console.log(term);

        const searchTxt = AppUtilityService.getFullTextSearch(term);
        if (AppUtilityService.isNotNull(searchTxt) && item) {

            //item.name
            const ftsVietTat = AppUtilityService.searchVietTat(item.name) || "";
            const checkVietTat = ftsVietTat.indexOf(searchTxt) > -1;
            const ftsFullTextSearch = AppUtilityService.getFullTextSearch(item.name) || "";
            const checkFullTextSearch = ftsFullTextSearch.indexOf(searchTxt) > -1;

            //item.groupName
            const ftsVietTat1 = AppUtilityService.searchVietTat(item.groupName) || "";
            const checkVietTat1 = ftsVietTat1.indexOf(searchTxt) > -1;
            const ftsFullTextSearch1 = AppUtilityService.getFullTextSearch(item.groupName) || "";
            const checkFullTextSearch1 = ftsFullTextSearch1.indexOf(searchTxt) > -1;

            return checkFullTextSearch || checkVietTat || checkFullTextSearch1 || checkVietTat1;
        }

        return item.name.startsWith(term);
    }
}
