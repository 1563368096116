import { Component, Injector, Input, OnInit } from '@angular/core';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { ModalComponentBase } from '@shared/common/modal-component-base';
import { BCT_DanhMucChiSoDto, BCT_DanhMucChiSoServiceProxy, ComboBoxEnumCode } from '@shared/service-proxies/service-proxies';
import * as $ from 'jquery';
import { finalize } from 'rxjs/operators';

@Component({
    templateUrl: './chi-so-bao-cao-tet-edit-modal.component.html',
})
export class ChiSoBaoCaoTetEditModalComponent extends ModalComponentBase implements OnInit {
    formId = 'formValidateChiSoBaoCaoTet';

    @Input() dataItem: BCT_DanhMucChiSoDto;
    ComboBoxEnumCode = ComboBoxEnumCode;
    constructor(injector: Injector, private _BCT_DanhMucChiSoService: BCT_DanhMucChiSoServiceProxy) {
        super(injector);
    }

    ngOnInit(): void {
        if (this.dataItem.id > 0) {
            this.getInfoById(this.dataItem.id);
        } else {
            this.clearForm();
        }
    }

    getInfoById(id: number): void {
        abp.ui.setBusy();
        this._BCT_DanhMucChiSoService
            .getById(id)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((response) => {
                this.dataItem = response;
            });
    }

    save(): void {
        if (AppUtilityService.IsNullValidateForm(this.formId)) {
            abp.notify.error(this.l('_BanCanNhapDuDuLieuCacTruongCoDauSaoDo'));
            return;
        }
        abp.ui.setBusy();
        this._BCT_DanhMucChiSoService
            .createOrUpdate(this.dataItem)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((result) => {
                if (result.isSuccessful) {
                    this.notify.success(this.l('SavedSuccessfully'));
                    if (this.isContinue) {
                        this.clearForm();
                    } else {
                        this.success(result.dataResult);
                    }
                } else {
                    this.notify.error(this.l('HasError'));
                }
            });
    }

    clearForm() {
        this.dataItem = Object.assign(new BCT_DanhMucChiSoDto(), {
            isDungMaHeThong: true,
            isActive: true,
            isKham: true,
        });
    }

    //#region UX_JQuery

    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {
            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    dropDrapModal() {
        let modalContent: any = $('.ant-modal-content');
        modalContent.draggable({
            handle: '.ant-modal-header',
        });
    }

    ngAfterViewInit(): void {
        this.dropDrapModal();
        this.fullScreenClick();
    }
    //#endregion
}
