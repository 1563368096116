import { Component, Injector, Input, OnInit } from "@angular/core";
import { AppUtilityService } from "@app/shared/common/custom/utility.service";
import { ModalComponentBase } from "@shared/common/modal-component-base";
import {  AMR_SpecimenServiceProxy, ComboBoxEnumCode,  TRANG_THAI_AMR, AMR_SpecimenDto, ComboBoxTableCode, PHAN_LOAI_BENH_PHAM } from "@shared/service-proxies/service-proxies";
import * as $ from 'jquery';
import { finalize } from "rxjs/operators";
@Component({
    selector: 'amr-chung-loai-vi-khuan-edit',
    templateUrl: './amr-benh-pham-edit-modal.component.html',
    // styleUrls: ['./arm-khang-sinh-edit-modal.component.scss']
})
export class AMRBenhPhamEditComponent extends ModalComponentBase implements OnInit {
    formId = 'formBenhPham';
    @Input() dataItem: AMR_SpecimenDto;
    @Input() isView = false;
    TRANG_THAI_AMR = TRANG_THAI_AMR;
    PHAN_LOAI_BENH_PHAM = PHAN_LOAI_BENH_PHAM;
    ComboBoxEnumCode = ComboBoxEnumCode;
    ComboBoxTableCode = ComboBoxTableCode;
    constructor(
        injector: Injector,
        private _dataService: AMR_SpecimenServiceProxy,
        ) {
        super(injector);
    }

    ngOnInit(): void {
        if (this.dataItem.id > 0) {
            this.getInfoById(this.dataItem.id);
        } else {
            this.clearForm();
            this.dataItem.spec_group = PHAN_LOAI_BENH_PHAM.KHONG_PHAN_LOAI;
            this.dataItem.human = 1;
        }
    }

    getInfoById(id): void {
        abp.ui.setBusy();
        this._dataService
            .getById(id)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((response) => {
                this.dataItem = response;
            });
    }

    save(){
        if (AppUtilityService.IsNullValidateForm(this.formId)) {
            abp.notify.error(this.l('_BanCanNhapDuDuLieuCacTruongCoDauSaoDo'));
            return;
        }
        abp.ui.setBusy();
        this._dataService
            .createOrUpdate(this.dataItem)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((result) => {
                if (result.isSuccessful) {
                    this.notify.success(this.l('SavedSuccessfully'));
                    if (this.isContinue) {
                        this.clearForm();
                    } else {
                        this.success(result.dataResult);
                    }
                } else {
                    this.notify.error(result.errorMessage);
                }
            });
    }

    changePhanNhomChinhKhac($event) {

        this.dataItem.group_name_vi = $event?.data.name_vi;

    }
    clearForm() {
        this.dataItem = Object.assign(new AMR_SpecimenDto());
    }

    //#region UX_JQuery

    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {
            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    dropDrapModal() {
        let modalContent: any = $('.ant-modal-content');
        modalContent.draggable({
            handle: '.ant-modal-header',
        });
    }

    ngAfterViewInit(): void {
        this.dropDrapModal();
        this.fullScreenClick();
    }
      // Getters and Setters for Checkbox Binding
      get isHuman(): boolean {
        return this.dataItem.human === 1;
    }

    set isHuman(value: boolean) {
        this.dataItem.human = value ? 1 : 0;
    }

    get isAnimal(): boolean {
        return this.dataItem.animal === 1;
    }

    set isAnimal(value: boolean) {
        this.dataItem.animal = value ? 1 : 0;
    }
}
